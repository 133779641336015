import { Category } from '../models/ui-layout.model';

export const UI_CONFIGS: Category[] = [
  {
    title: 'Performance',
    events: [
      {
        name: 'State of Charge (Electric Vehicles)',
        description: `State of Charge events occur when an electric vehicle's charge goes below a set percentage.`,
        footer: 'Note: Events only apply to heavy-duty electric vehicles.',
        lastUserSettingName: 'company_threshold_state_of_charge_(electric_vehicles)_last_user',
        thresholds: [
          {
            name: 'Critical',
            icon: 'info',
            settings: [
              {
                startDescription: 'Create a critical event when state of charge goes below',
                endDescription: 'percent',
                settingName: 'company_threshold_low_soc_critical',
              },
            ],
          },
          {
            name: 'Minor',
            icon: 'report_problem',
            settings: [
              {
                startDescription: 'Create a minor event when state of charge goes below',
                endDescription: 'percent',
                settingName: 'company_threshold_low_soc_minor',
              },
            ],
          },
        ],
      },
      {
        name: 'Vehicle Idling',
        description: `Idling events occur when a vehicle has been idling beyond the idle threshold.`,
        footer: '',
        lastUserSettingName: 'company_threshold_idle_last_user',
        thresholds: [
          {
            name: 'Critical',
            icon: 'info',
            settings: [
              {
                startDescription: 'Creates a critical event when idle goes above',
                endDescription: 'minutes',
                settingName: 'company_threshold_idle_critical',
              },
            ],
          }
        ],
      },
      {
        name: 'Asset Utilization',
        description: `Asset utilization events occur when a vehicle's hours or miles used goes above or below the threshold.`,
        footer: '',
        lastUserSettingName: '',
        thresholds: [
          {
            name: 'Target hours',
            icon: 'alarms',
            styleClasses: 'd-flex align-items space-between',
            settings: [
              {
                startDescription: 'The asset should be used for',
                settingName: 'asset_utilization_target_hours',
                endDescription: `target hours per day.`,
              },
              {
                settingName: 'asset_utilization_hours_include_weekends',
                endDescription: `Includes weekends`,
              },
            ],
          },
          {
            name: 'Target miles',
            icon: 'trending_up',
            styleClasses: 'd-flex align-items space-between',
            settings: [
              {
                startDescription: 'The asset should be used for',
                settingName: 'asset_utilization_target_miles',
                endDescription: `target miles per day.`,
              },
              {
                settingName: 'asset_utilization_miles_include_weekends',
                endDescription: `Includes weekends`,
              },
            ],
          },
          {
            name: 'Utilization range',
            icon: 'adjust',
            settings: [
              {
                startDescription: 'The range of how much the asset would be used in an ideal situation is between',
                settingName: 'asset_utilization_range_min',
                endDescription: 'and'
              },
              {
                settingName: 'asset_utilization_range_max',
                endDescription: `percent.`,
              },
              {
                startDescription: `Assets that fall below this range will be considered "<span class='shape square'></span> under utilized" and assets above this range will be considered "<span class='shape triangle'></span> over utilized".`,
                styleClasses: 'separate-block',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Maintenance',
    events: [
      {
        name: 'Vehicle Battery',
        description: 'Vehicle Battery events occur when Zonar detects low battery voltage on your vehicle.',
        lastUserSettingName: 'company_threshold_vehicle_battery_last_user',
        thresholds: [
          {
            name: 'Critical',
            icon: 'info',
            settings: [
              {
                startDescription: 'Create a critical event when voltage is below',
                endDescription: 'volts',
                settingName: 'company_threshold_vehicle_battery_critical',
              },
            ],
          },
          {
            name: 'Minor',
            icon: 'report_problem',
            settings: [
              {
                startDescription: 'Create a minor event when voltage is below',
                endDescription: 'volts',
                settingName: 'company_threshold_vehicle_battery_minor',
              },
            ],
          },
        ],
      },
      {
        name: 'Engine Fault',
        description: 'Engine Fault events occur when one or more diagnostic trouble code events trigger.',
        lastUserSettingName: 'company_threshold_engine_fault_last_user',
        thresholds: [
          {
            name: 'Critical',
            icon: 'info',
            settings: [
              {
                startDescription: 'Create a critical event when Check Engine lights up',
              },
            ],
          },
          {
            name: 'Minor',
            icon: 'report_problem',
            settings: [
              {
                startDescription: 'Create a minor event when the Check Engine lights and Malfunction Indicator Lamp (MIL) are up',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Safety',
    events: [
      {
        name: 'Posted Speed',
        description: `Speeding events occur when assets in your fleet exceed the posted speed limit.`,
        footer: '',
        lastUserSettingName: 'company_threshold_posted_speed_last_user',
        thresholds: [
          {
            name: 'Critical',
            icon: 'info',
            settings: [
              {
                startDescription: 'Create a critical alert when an assets has exceeded the posted speed limit by',
                endDescription: 'mph for the defined duration.',
                settingName: 'company_threshold_posted_speed_critical',
              },
            ],
          },
          {
            name: 'Minor',
            icon: 'report_problem',
            settings: [
              {
                startDescription: 'Create a minor alert when an assets has exceeded the posted speed limit by',
                endDescription: 'mph for the defined duration.',
                settingName: 'company_threshold_posted_speed_minor',
              },
            ],
          },
          {
            name: 'Duration',
            icon: 'watch_later',
            settings: [
              {
                startDescription: 'A speeding event is created when speed exceeds either threshold for',
                endDescription: 'seconds or longer.',
                settingName: 'company_threshold_posted_speed_duration',
              },
            ],
          },
        ],
      },
    ],
  },
];
